import {
  PRICING_FEATURE_DATA,
  PRICING_SECTIONS,
  PRICING_TIER_DATA,
  PRICING_TIER_IDS,
  PricingPeriod,
  type FeatureDataType,
  type FeatureType,
  type PricingTierId,
} from '@/shared/constants/pricing'
import type { ActivityType, IntegrationName } from '@prisma/client'

export function getSections(integrationName?: IntegrationName) {
  return PRICING_SECTIONS.filter(section =>
    integrationName
      ? section.integrationSpecificData?.[integrationName]?.isVisible !== false
      : true
  )
}
export function getTiers(
  integrationName?: IntegrationName
): readonly PricingTierId[] {
  if (!integrationName) {
    return PRICING_TIER_IDS
  }
  return PRICING_TIER_IDS.filter(
    tierId =>
      PRICING_TIER_DATA[tierId].integrationSpecificData?.[integrationName]
        ?.isVisible !== false
  )
}
export function sortAndFilterSectionFeatures({
  features,
  integrationName,
}: {
  features: FeatureDataType[]
  integrationName?: IntegrationName
}): FeatureDataType[] {
  return features
    .filter(feature => {
      if (!integrationName) {
        return feature.isVisible ?? true
      }

      const integrationSpecificData =
        feature.integrationSpecificData?.[integrationName]
      const isVisibleForIntegration = integrationSpecificData?.isVisible

      return isVisibleForIntegration ?? feature.isVisible ?? true
    })
    .slice()
    .sort((a, b) => {
      if (!integrationName) {
        return 0
      }
      const aOrder = a.integrationSpecificData?.[integrationName]?.order
      const bOrder = b.integrationSpecificData?.[integrationName]?.order
      if (aOrder === undefined && bOrder === undefined) {
        return 0
      }
      if (aOrder === undefined) {
        return 1
      }
      if (bOrder === undefined) {
        return -1
      }
      return aOrder - bOrder
    })
}

export function getFeatureData(
  type: FeatureType | ActivityType,
  integrationName?: IntegrationName
): FeatureDataType | null {
  const featureData =
    type in PRICING_FEATURE_DATA &&
    (PRICING_FEATURE_DATA[
      type as keyof typeof PRICING_FEATURE_DATA
    ] as FeatureDataType)
  if (!featureData) {
    return null
  }

  if (!integrationName) {
    return featureData
  }

  const integrationSpecificData =
    'integrationSpecificData' in featureData &&
    featureData.integrationSpecificData &&
    integrationName in featureData.integrationSpecificData &&
    featureData.integrationSpecificData[
      integrationName as keyof FeatureDataType['integrationSpecificData']
    ]

  if (!integrationSpecificData) {
    return featureData
  }

  return {
    ...featureData,
    ...(integrationSpecificData as Partial<FeatureDataType>),
  }
}

export const parsePriceLookupKey = (
  lookupKey?: string | null
): {
  pricingTierId: PricingTierId
  pricingPeriod: PricingPeriod
} | null => {
  const [stripePricingTierId, stripePricingPeriod] = lookupKey?.split('_') ?? []
  const pricingTierId = PRICING_TIER_IDS.find(
    pricingTierId =>
      pricingTierId.toLowerCase() === stripePricingTierId?.toLowerCase()
  )
  const pricingPeriod = Object.values(PricingPeriod).find(
    pricingPeriod =>
      pricingPeriod.toLowerCase() === stripePricingPeriod?.toLowerCase()
  )

  if (!pricingTierId || !pricingPeriod) {
    console.log('Invalid price lookup key %s', lookupKey, {
      stripePricingTierId,
      stripePricingPeriod,
      pricingTierId,
      pricingPeriod,
      PRICING_TIER_IDS,
      PricingPeriod,
    })
    return null
  }

  return {
    pricingTierId,
    pricingPeriod,
  }
}
