'use client'

import { AVAILABLE_INTEGRATION_NAMES } from '@/shared/constants/integration'
import { useIntegrationPermissionErrors } from '@/web/hooks/use-integration-permission-errors'
import { type IntegrationName } from '@prisma/client'

export const useAllIntegrationPermissionErrors = () => {
  const integrationPermissionErrors: Partial<
    Record<IntegrationName, ReturnType<typeof useIntegrationPermissionErrors>>
  > = {}
  for (const integrationName of AVAILABLE_INTEGRATION_NAMES.slice().sort()) {
    integrationPermissionErrors[integrationName] =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useIntegrationPermissionErrors(integrationName)
  }

  return integrationPermissionErrors
}
